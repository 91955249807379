import { defineStore } from 'pinia';
import IndexAPI from "@/services/v2/IndexAPI";
import GetAPI from "@/services/v2/GetAPI";
import PatchAPI from "@/services/v2/PatchAPI";
import PostAPI from "@/services/v2/PostAPI";
import Errors from "@/services/v2/Errors";
import dayjs from 'dayjs';
import axios from "axios";
import createFormData from "@/util/createFormData";

export const useAssetStore = defineStore('asset', {
  state: () => ({
    assets: [],
    asset: null,
    assetMeta: null,
    phantomAsset: {},
    assetCreated: null,
    meta: [],
    loading: false,
    error: null,
    errors: null,
  }),

  getters: {
    currency(state) {
      return state.asset?.maintainableEntity?.Site?.Organisation?.currency.code;
    }
  },

  actions: {
    async fetch(config) {
      this.assets = [];
      this.meta = {};
      this.loading = true;
      this.error = null;
      this.errors = null;

      try {
        const response = await IndexAPI.fetch('assets', config);
        this.assets = response.data;
        this.assets = this.addNames(this.assets);
        this.assets = this.addThumbnails(this.assets);
        this.meta = response.meta;
      } catch (error) {
        this.error = error;
        this.errors = Errors.getErrors(error);
      } finally {
        this.loading = false;
      }
    },
    addThumbnails(assets) {
      return assets.map((asset) => {
        const assetClone = { ...asset };
        assetClone.assetModel.thumbnail = this.getThumbnail(asset);
        return assetClone;
      });
    },
    getThumbnail(asset) {
      return asset.assetModel.media?.find(
        (media) => media.collection_name === 'asset_model_thumbnail'
          && media.file_exists
      );
    },
    addNames(assets) {
      return assets.map((asset) => {
        const assetClone = { ...asset };
        assetClone.name = this.getName(asset);
        return assetClone;
      });
    },
    getName(asset) {
      const parts = [];
      parts.push(asset.assetModel?.name);
      if (asset.label) {
        parts.push(`(#${asset.label})`);
      }
      return parts.join(' ');
    },
    async get(config) {
      this.asset = null;
      this.loading = true;
      this.error = null;
      this.errors = null;

      try {
        const response = await GetAPI.get('assets', config);
        this.asset = response.data;
        this.asset.name = this.getName(this.asset);
        this.assetMeta = response.meta;
      } catch (error) {
        this.error = error;
        this.errors = Errors.getErrors(error);
      } finally {
        this.loading = false;
      }
    },
    async create(data) {
      this.loading = true;
      this.error = null;
      this.errors = null;
      this.assetCreated = null;

      try {
        const response = await PostAPI.post(`assets`, {
          selectable: true,
          ...data
        });
        this.assetCreated = response.data;
      } catch (error) {
        this.error = error;
        this.errors = Errors.getErrors(error);
      } finally {
        this.loading = false;
      }
    },
    async update(id, data) {
      this.loading = true;
      this.error = null;
      this.errors = null;

      try {
        const response = await PatchAPI.patch(`assets`, id, {
          id: id,
          ...data,
        });
      } catch (error) {
        this.error = error;
        this.errors = Errors.getErrors(error);
      } finally {
        this.loading = false;
      }
    },
    async uploadMedia(id, data) {
      this.loading = true;
      this.error = null;
      this.errors = null;

      try {
        const response = await axios({
          method: 'POST',
          url: `assets/${id}/media`,
          headers: {
            'content-type': 'multipart/form-data',
          },
          responseType: 'json',
          data: createFormData(data),
        });
      } catch (error) {
        this.error = error;
        this.errors = Errors.getErrors(error);
      } finally {
        this.loading = false;
      }
    },
    async deleteMedia(id, media) {
      this.loading = true;
      this.error = null;
      this.errors = null;

      try {
        const response = await axios({
          method: 'DELETE',
          url: `assets/${id}/media/${media}`,
          responseType: 'json',
        });
      } catch (error) {
        this.error = error;
        this.errors = Errors.getErrors(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
